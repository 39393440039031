import React from "react";

function Footer() {
  return (
    <div className="Footer" id="contact">
      <div className="container">
        <div className="contact-heading">
          <h2>Contact US</h2>
        </div>
        <div className="row">
          <div className="footer-style col-md-6 col-12">
            <p className="goto-link">
              <b>Email : </b>{" "}
              <a href="mailto:  globalitsolutionllc@gmail.com">
                globalitsolutionllc@gmail.com
              </a>
            </p>
            <p className="goto-link">
              <b>Phone no : </b>{" "}
              <a href="tel:+17039443380">
                (800) 224-8260, (253) 293-1300, (703) 214-9089, (703) 651-0038
              </a>
            </p>
            <p>
              <b>Address : </b>9300 Peabody St Unit 204 Manassas, VA 20110, USA
            </p>
          </div>
          <div className="col-md-6 col-12">
            <form className="row g-3">
              <div className="col-md-6">
                <label htmlFor="inputEmail4" className="form-label">
                  First Name
                </label>
                <input type="email" className="form-control" id="inputEmail4" />
              </div>
              <div className="col-md-6">
                <label htmlFor="inputPassword4" className="form-label">
                  Last Name
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="inputPassword4"
                />
              </div>
              <div className="col-12">
                <label htmlFor="inputAddress" className="form-label">
                  Email
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="inputAddress"
                  // placeholder="1234 Main St"
                />
              </div>
              <div className="col-12">
                <label htmlFor="inputAddress2" className="form-label">
                 Subject
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="inputAddress2"
                  // placeholder="/"
                />
              </div>
              <div className="col-12">
                <label htmlFor="inputAddress2" className="form-label">
                 Message
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="inputAddress2"
                  // placeholder="/"
                />
              </div>
              <div className="col-12">
                <p>
                  Do you agree to opt-in to receive Text messages? You may
                  opt-out at any time by replying "STOP".
                </p>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="gridCheck"
                  />
                  <label className="form-check-label" htmlFor="gridCheck">
                    I agree to opt-in
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="gridCheck"
                  />
                  <label className="form-check-label" htmlFor="gridCheck">
                    I do not agree to opt-in
                  </label>
                </div>
              </div>
              <div className="col-12">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
