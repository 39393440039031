import React from "react";
import Header from "../Components/Layout/Header";

function Policy() {
  return (
    <div className="policy">
      <Header />
      <div className="container">
        <h1>Privacy Policy</h1>
        <div className="paragraph">
          <h3>Introduction:</h3>
          <p>
            Your privacy is important to us. This policy explains how we
            collect, use, and protect your personal information.
          </p>
          <h3>Information We Collect:</h3>
          <h3>Personal Information:</h3>
          <p>
            {" "}
            Name, email address, phone number, and payment details (if
            applicable).
          </p>{" "}
          <h3>Usage Data:</h3>
          <p>
            Information about how you interact with our website and services,
            such as IP address, browser type, and pages visited.
          </p>
          <h3>How We Use Your Information:</h3>
          <p>
            To provide and improve our services. To send updates, promotional
            materials, and other communications.
          </p>
          To comply with legal obligations. <br />
          <h3>Sharing Information:</h3>
          <p>
            We do not sell or rent your personal information. However, we may
            share it with trusted service providers to help operate our business
            or as required by law.
          </p>
          <h3>Cookies and Tracking:</h3>
          <p>
            We use cookies to improve user experience and analyze website
            performance. You can adjust your browser settings to manage cookie
            preferences.
          </p>
          <h3>Data Security:</h3>
          <p>
            {" "}
            We implement appropriate security measures to protect your personal
            information from unauthorized access, alteration, or disclosure.
          </p>
          <h3>Your Rights:</h3>
          <p>
            Access your personal information, Request correction or deletion of
            your information, Opt out of promotional communications.
          </p>
          <h3>Data Retention:</h3><p> We retain personal information only as long
          as necessary to fulfill the purposes outlined in this policy.</p>
          <h3> Children's Privacy:</h3>
          <p>
            Our services are not intended for children under 13. We do not
            knowingly collect personal information from children.
          </p>
          <h3>Changes to Privacy Policy:</h3>
          <p>
            We may update this policy periodically. Changes will be posted on
            our website with the "Last Updated" date.
          </p>
          <h3>Contact Information:</h3>
          <p>
            For privacy-related questions, contact us at
            globalitsolutionllc@gmail.com.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Policy;
