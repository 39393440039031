import React from 'react'
import Header from '../Components/Layout/Header'
import Hero from '../Components/Elements/Hero'
import Software from '../Components/Elements/Software'
import Aboutus from '../Components/Elements/Aboutus'
import Application from '../Components/Elements/Application'
import Portfolio from '../Components/Elements/Portfolio'
import Footer from '../Components/Layout/Footer'

function Home() {
  return (
    <div>
    <Header/>
    <Hero/>
    <Software/>
    <Application/>
    <Portfolio/>
    <Aboutus/>
    <Footer/>
    </div>
  )
}

export default Home