import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function Header() {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`Header ${isSticky ? "sticky" : ""}`}>
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src="Assets/logo.png" className="logo" alt="" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto ms-auto mb-10 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link" aria-current="page" href="/">
                  Portfolio
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#about">
                  About US
                </a>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="/"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Services
                </a>
                <ul className="dropdown-menu">
                  <div className="items">
                    <div className="itemlist-1">
                      <li>
                        <a className="dropdown-item" href="/">
                          Websites
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/">
                          Application Development
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/">
                          Software Development
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/">
                          Mobile Apps
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/">
                          Logo & Brand Identity
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/">
                          UI / UX designing
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/">
                          Art & Illustration
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/">
                          Architecture & Building Design
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/">
                          Product & Gaming
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/">
                          Visual Design
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/">
                          Business Consultants
                        </a>
                      </li>
                    </div>
                    <div className="service-list2">
                      <li>
                        <a className="dropdown-item" href="/">
                          Print Design
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/">
                          Marketing Design
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/">
                          Search
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/">
                          Social
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/">
                          Methods & Techniques
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/">
                          Editing & Post-Production
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/">
                          Motion Graphics
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/">
                          Social & Marketing Videos
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/">
                          Product Videos
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/">
                          Filmed Video Production
                        </a>
                      </li>
                    </div>
                  </div>
                </ul>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/policy">
                  Privacy Policy
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/conditions">
                  Terms & Conditions
                </Link>
              </li>
            </ul>
            <div>
              <button className="btn btn-primary" href="#contact">
                Contact US
              </button>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;
