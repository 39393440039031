import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import Header from './Components/Layout/Header';
// import Hero from './Components/Elements/Hero';
// import Software from './Components/Elements/Software';
// import Application from './Components/Elements/Application';
// import Footer from './Components/Layout/Footer';
// import Aboutus from './Components/Elements/Aboutus';
// import Portfolio from './Components/Elements/Portfolio';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Conditions from './Pages/Conditions';
import Policy from './Pages/Policy';

function App() {
  return (
    <div>
      <BrowserRouter>
      <Routes>
       <Route index element={<Home/>}/>
       <Route path='/home' element={<Home/>}/>
       <Route path='/policy' element={<Policy/>}/>
       <Route path='/conditions' element={<Conditions/>}/>
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
