import React from "react";
import Header from "../Components/Layout/Header";

function Conditions() {
  return (
    <div className="conditions">
      <Header />
      <div className="container">
        <h1>Terms & Conditions</h1>
        <div className="paragraph">
          <h3>Acceptance of Terms:</h3>
          <p>
            {" "}
            By accessing and using our website and services, you agree to be
            bound by these Terms and Conditions. If you do not agree, you may
            not use our services.
          </p>
          <h3>Eligibility:</h3>
          <p>
            {" "}
            You must be at least 18 years old to use our services. By using our
            services, you confirm that you meet this requirement.
          </p>
          <h3>Service Use: </h3>
          <p>
            {" "}
            Our services are provided "as is." We make no guarantees regarding
            their availability, accuracy, or reliability.
          </p>
          <h3>User Responsibilities:</h3>
          <p>
            {" "}
            You agree to use our services lawfully and refrain from any activity
            that could harm our operations, infrastructure, or reputation.{" "}
          </p>
          <h3>Intellectual Property:</h3>
          <p>
            All content on our website, including text, images, and branding, is
            our property or used with permission. Unauthorized use is
            prohibited.
          </p>
          <h3>Third-Party Links:</h3>
          <p>
            {" "}
            Our website may include links to third-party sites. We are not
            responsible for their content or practices.
          </p>
          <h3> Limitation of Liability:</h3>{" "}
          <p>
            To the extent permitted by law, we are not liable for damages
            resulting from your use of our services.
          </p>
          <h3>Changes to Terms:</h3>
          <p>
            We reserve the right to modify these Terms and Conditions at any
            time. Updates will be posted on our website.{" "}
          </p>
          <h3>Termination:</h3>
          <p>
            We may suspend or terminate your access to our services at our
            discretion, without notice.{" "}
          </p>
          <h3>Contact Information:</h3>
          <p>
            For questions about these Terms and Conditions, please contact us at
            globalitsolutionllc@gmail.com.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Conditions;
